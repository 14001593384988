import React, {useState, useEffect} from 'react';
import {Check, Copy, ExternalLink, FileText, RefreshCw, Loader2, Info, History, Download, Plus, Minus} from 'lucide-react';
import {Button} from '@/components/ui/button';
import {Textarea} from '@/components/ui/textarea';
import {Card, CardContent, CardHeader, CardTitle} from '@/components/ui/card';
import {Alert, AlertDescription, AlertTitle} from "@/components/ui/alert";
import FloatingHelpButton from "@/components/FloatingHelpButton";
import Layout from "@/components/Layout";
import api from "@/utils/api";
import { useCredit } from '@/hooks/useCredit';

const SUBSCRIPTION_CRM_URL = 'https://afh1lmpx0eddz0cs.mikecrm.com/zsjs2X3'
const SUCCESS_CODE = 10000;
const MAX_CHARS_PER_WORD = 30; // Maximum characters to consider as a single word
const MAX_WORD_COUNT = 500; // Maximum allowed words
const MAX_ROWS = 2; // Maximum number of input-output pairs

const AiRephraser = () => {
  const [rows, setRows] = useState([{
    inputText: '',
    rephrasedText: '',
    wordCount: 0,
    isLoading: false,
  }]);
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState(null);
  const { refreshCredit } = useCredit();
  const [rephrasingHistory, setRephrasingHistory] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);

  const addRow = () => {
    if (rows.length < MAX_ROWS) {
      setRows([...rows, {
        inputText: '',
        rephrasedText: '',
        wordCount: 0,
        isLoading: false,
      }]);
    }
  };

  const removeRow = (index) => {
    if (rows.length > 1) {
      const newRows = rows.filter((_, i) => i !== index);
      setRows(newRows);
    }
  };

  const handleTextChange = (index, text) => {
    const newRows = [...rows];
    const newWordCount = countWords(text);

    if (newWordCount > MAX_WORD_COUNT) {
      setError(`Text exceeds maximum word limit of ${MAX_WORD_COUNT} words`);
    } else {
      setError(null);
    }

    newRows[index] = {
      ...newRows[index],
      inputText: text,
      wordCount: newWordCount,
    };
    setRows(newRows);
  };

  const handleGetCode = () => {
    window.open(SUBSCRIPTION_CRM_URL, '_blank', 'noopener,noreferrer');
  };

  // const handleFileUpload = async (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     try {
  //       setIsLoading(true);
  //       if (file.type === 'application/pdf') {
  //         const formData = new FormData();
  //         formData.append('file', file);
  //
  //         const response = await fetch(BACKEND_URL + '/orchestrator/parse_file', {
  //           method: 'POST',
  //           body: formData,
  //         });
  //         const responseData = await response.json();
  //         console.log(responseData)
  //         setInputText(responseData.text);
  //         setWordCount(countWords(responseData.text));
  //       } else {
  //         const text = await readFileContent(file);
  //         setInputText(text);
  //         setWordCount(countWords(text));
  //       }
  //     } catch (error) {
  //       console.error('Error reading file:', error);
  //       // You might want to show an error message to the user here
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const countWords = (text) => {
    if (!text || !text.trim()) return 0;
    // First, normalize whitespace and trim the text
    const normalizedText = text.trim().replace(/\s+/g, ' ');
    // Split the text into potential words
    const words = normalizedText.split(' ');
    // Process each word to handle edge cases
    return words.reduce((acc, word) => {
      if (!word) return acc;

      // If word is longer than MAX_CHARS_PER_WORD (30 chars),
      // it might be multiple words stuck together
      if (word.length > MAX_CHARS_PER_WORD) {
        // 1. Split by capital letters (CamelCase)
        // "HelloWorld" -> ["Hello", "World"]
        const camelCaseSplit = word.split(/(?=[A-Z])/g);

        // 2. Split by numbers and punctuations
        // "thisIs1Word" -> ["thisIs", "1", "Word"]
        const withNumbersSplit = camelCaseSplit
            .flatMap(part => part.split(/(?<=\d)(?=[a-zA-Z])|(?<=[a-zA-Z])(?=\d)/g));

        // 3. Handle any remaining long parts by breaking them into chunks
        const finalParts = withNumbersSplit.flatMap(part => {
          if (part.length > MAX_CHARS_PER_WORD) {
            // Break very long strings into chunks of MAX_CHARS_PER_WORD
            const chunks = [];
            for (let i = 0; i < part.length; i += MAX_CHARS_PER_WORD) {
              chunks.push(part.slice(i, i + MAX_CHARS_PER_WORD));
            }
            return chunks;
          }
          return [part];
        });

        // Add the number of parts to our word count
        return acc + finalParts.length;
      }

      // If it's a normal word, just add 1 to our count
      return acc + 1;

    }, 0);
  };

  const fetchRephrasingHistory = async () => {
    try {
      setIsLoadingHistory(true);
      const response = await api.get('/humanize/history/');
      if (response.data.code === SUCCESS_CODE) {
        setRephrasingHistory(response.data.body.humanization_history || []);
      }
    } catch (error) {
      console.error('Error fetching rephrasing history:', error);
    } finally {
      setIsLoadingHistory(false);
    }
  };

  useEffect(() => {
    fetchRephrasingHistory();
  }, []);

  const formatDate = (timestamp) => {
    // Convert Unix timestamp (seconds) to milliseconds
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 3:
        return 'text-green-600 bg-green-50 border-green-200';
      case 1:
        return 'text-blue-600 bg-blue-50 border-blue-200';
      case 2:
        return 'text-red-600 bg-red-50 border-red-200';
      default:
        return 'text-gray-600 bg-gray-50 border-gray-200';
    }
  };

  const getDisplayStatus = (statusCode) => {
    switch (statusCode) {
      case 1:
        return 'Processing'
      case 2:
        return 'Failed'
      case 3:
        return 'Completed'
    }
  };

  const handleRephrase = async () => {
    // Check if any input is empty
    const hasEmptyInput = rows.some(row => !row.inputText.trim());
    if (hasEmptyInput) {
      setError('Please enter text in all input fields');
      return;
    }

    // Set loading state for all rows
    const newRows = rows.map(row => ({
      ...row,
      isLoading: true
    }));
    setRows(newRows);
    setError(null);

    try {
      const response = await api.post('/humanize/multi-run', {
        ai_contents: rows.map(row => row.inputText),
      });

      const data = response.data;
      if (data.code !== SUCCESS_CODE) {
        throw new Error(data.message);
      }

      // Update all rows with their respective outputs
      const updatedRows = rows.map((row, index) => ({
        ...row,
        rephrasedText: data.body.contents[index],
        isLoading: false
      }));
      setRows(updatedRows);

      await fetchRephrasingHistory();
      refreshCredit();
    } catch (error) {
      console.error('Error rephrasing text:', error);
      // Check for Cloudflare 524 timeout
      if (error.response && error.response.status === 524) {
        setError('The request is taking longer than 100 seconds. Please refresh the page later to check your rewritten response in the history section.');
      } else {
        setError(error.message);
      }
      // Reset loading state on error
      setRows(rows.map(row => ({
        ...row,
        isLoading: false
      })));
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(rows.map(row => row.rephrasedText).join('\n'));
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy text: ', error);
      setError('Failed to copy text.');
    }
  };

  const handleDownload = async (uniqueId) => {
    try {
      const response = await api.get(`/humanize/download/output/${uniqueId}`, {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const filename = getFileNameFromContentDisposition(response) || 'rephrased.txt';
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
      setError('Failed to download file.');
    }
  };

  const getFileNameFromContentDisposition = (response) => {
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'rephrased.txt';
    if (contentDisposition) {
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  };

  return (
    <Layout>
      <Card className="max-w-6xl mx-auto">
        <CardHeader>
          <CardTitle className="text-center text-2xl">
            AI Humanizer
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <Alert variant="info" className="bg-pink-50">
            <div className="px-4">
              <AlertTitle className="text-base font-medium mb-2">How to Use</AlertTitle>
              <AlertDescription>
                <ol className="list-decimal ml-4 space-y-2">
                  <li className="pl-2">Each humanization takes <span className="font-medium">5</span> credits.
                  </li>
                  <li className="pl-2">You can now humanize up to <span className="font-medium">2</span> texts simultaneously to save time!</li>
                  <li className="pl-2">Click <span className="font-medium">Get Credits</span> to go to the checkout page.
                    After purchasing, you can check your purchase history in user profile page.
                  </li>
                  <li className="pl-2">Click <span className="font-medium">Rephrase All</span>. The rewriting process may
                    take some time, please be patient.
                  </li>
                  <li className="pl-2">If you encounter any issues, please contact our customer service.</li>
                  <li className="pl-2">🔍 Please contact customer service if the humanized content still has AI rate greater than 20%.</li>
                </ol>
                <div className="mt-4">
                  <p>
                    ⏳ Due to our newer, more complex algorithm, processing time may be longer (around 1 minute).
                    Thank you for your patience! 🙏
                  </p>
                </div>
              </AlertDescription>
            </div>
          </Alert>

          <div className="flex flex-col space-y-8">
            {error && (
              <Alert variant="destructive">
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {/* Input-Output Pairs */}
            {rows.map((row, index) => (
              <Card key={index} className="p-4">
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold">Rephrase {index + 1}</h3>
                    <div className="space-x-2">
                      {index === rows.length - 1 && rows.length < MAX_ROWS && (
                        <Button
                          variant="default"
                          size="sm"
                          onClick={addRow}
                          className="bg-gray-900 text-white hover:bg-gray-700"
                        >
                          <Plus className="h-4 w-4 mr-1"/>
                          Add Rephrase
                        </Button>
                      )}
                      {rows.length > 1 && (
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => removeRow(index)}
                        >
                          <Minus className="h-4 w-4 mr-1"/>
                          Remove
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <span className="font-semibold">Original Text</span>
                      <Textarea
                        placeholder="Enter your text here..."
                        value={row.inputText}
                        onChange={(e) => handleTextChange(index, e.target.value)}
                        className="h-64"
                      />
                      <div className="flex items-center space-x-2">
                        <FileText className="h-4 w-4"/>
                        <span className={row.wordCount > MAX_WORD_COUNT ? 'text-red-500' : ''}>
                          Word count: {row.wordCount} / {MAX_WORD_COUNT}
                        </span>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <span className="font-semibold">Rephrased Text</span>
                      <div className="relative">
                        <Textarea
                          value={row.rephrasedText}
                          readOnly
                          className="h-64"
                          placeholder="Rephrased text will appear here..."
                        />
                        {row.isLoading && (
                          <div className="absolute inset-0 flex items-center justify-center bg-background/50">
                            <Loader2 className="h-8 w-8 animate-spin text-primary"/>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            ))}

            {/* Buttons */}
            <div className="flex justify-between mt-6">
              <Button
                variant="outline"
                size="lg"
                onClick={handleGetCode}
                className="whitespace-nowrap"
              >
                <ExternalLink className="mr-2 h-4 w-4"/>
                Get Credits
              </Button>
              <Button
                onClick={handleRephrase}
                disabled={rows.some(row => row.isLoading) ||
                  rows.some(row => !row.inputText.trim()) ||
                  rows.some(row => row.wordCount > MAX_WORD_COUNT)}
                size="lg"
                className="px-8"
              >
                {rows.some(row => row.isLoading) ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                    Rephrasing...
                  </>
                ) : (
                  <>
                    <RefreshCw className="mr-2 h-4 w-4"/>
                    Rephrase All
                  </>
                )}
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className="max-w-6xl mx-auto mt-6">
        <CardHeader>
          <CardTitle className="flex items-center space-x-2">
            <History className="h-5 w-5"/>
            <span>History</span>
          </CardTitle>
        </CardHeader>
        <CardContent>
          {isLoadingHistory ? (
            <div className="text-center py-4">Loading history...</div>
          ) : rephrasingHistory.length === 0 ? (
            <div className="text-center py-4 text-gray-500">
              No rephrasing history available
            </div>
          ) : (
            <div className="relative w-full overflow-auto">
              <table className="w-full caption-bottom text-sm">
                <thead className="[&_tr]:border-b">
                  <tr className="border-b transition-colors hover:bg-muted/50">
                    <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                      Date
                    </th>
                    <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                      Status
                    </th>
                    <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                      ID
                    </th>
                    <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rephrasingHistory.map((item) => (
                    <tr
                      key={item.unique_id}
                      className="border-b transition-colors hover:bg-muted/50"
                    >
                      <td className="p-4 align-middle">
                        {formatDate(item.created_at)}
                      </td>
                      <td className="p-4 align-middle">
                        <span className={`px-2 py-1 rounded-full text-xs font-medium border ${getStatusStyle(item.status)}`}>
                          {getDisplayStatus(item.status)}
                        </span>
                      </td>
                      <td className="p-4 align-middle font-medium">
                        {item.unique_id}
                      </td>
                      <td className="p-4 align-middle">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleDownload(item.unique_id)}
                          disabled={item.status !== 3}
                          className="h-8 px-2"
                        >
                          <Download className="h-4 w-4 mr-1" />
                          Download
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </CardContent>
      </Card>
      <div className="max-w-6xl mx-auto mt-8 pt-4 border-t text-center text-sm text-gray-500">
        <p>Copyright © 2024 StraightA</p>
      </div>
    </Layout>
  );
};

export default AiRephraser;
