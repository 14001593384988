import React from 'react';
import { Download, FileText } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import Layout from "@/components/Layout";
import api from "@/utils/api";

const DownloadManual = () => {
    const handleDownload = async () => {
        try {
            const response = await api.get('/download/manual/', {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const filename = 'straight-a-manual.pdf';
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading manual:', error);
        }
    };

    return (
        <Layout>
            <Card className="max-w-3xl mx-auto">
                <CardHeader>
                    <CardTitle className="text-center text-2xl">
                        Straight A User Manual
                    </CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                    <Alert variant="info" className="bg-pink-50">
                        <div className="px-4">
                            <AlertTitle className="text-base font-medium mb-2">
                                Download User Manual
                            </AlertTitle>
                            <AlertDescription>
                                Get detailed instructions on how to use our products effectively.
                                The manual includes best practices, tips, and troubleshooting guides.
                            </AlertDescription>
                        </div>
                    </Alert>

                    <div className="flex justify-center mt-6">
                        <Button
                            onClick={handleDownload}
                            size="lg"
                            className="px-8"
                        >
                            <Download className="mr-2 h-5 w-5" />
                            Download Manual
                        </Button>
                    </div>
                </CardContent>
            </Card>

            <div className="max-w-3xl mx-auto mt-8 pt-4 border-t text-center text-sm text-gray-500">
                <p>Copyright © 2024 StraightA</p>
            </div>
        </Layout>
    );
};

export default DownloadManual;
