import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AuthenticatedRoute = ({ children }) => {
    const { isAuthenticated, loading } = useAuth();
    const location = useLocation();

    if (loading) {
        return <div>Loading...</div>; // Or your loading component
    }

    if (!isAuthenticated) {
        console.log('Not authenticated');
        return <Navigate 
            to="/login" 
            state={{ from: location.pathname }} 
            replace 
        />;
    }

    return children;
};

export default AuthenticatedRoute;
