import React, {useEffect, useState} from 'react';
import {FileText, LogOut, Settings, CreditCard, Check, RefreshCw, FileDown, BookMarked, Handshake} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useAuth } from '@/contexts/AuthContext';
import { useDispatch, useSelector} from "react-redux";
import {fetchUserProfile} from "@/redux/slices/userSlice";
import FloatingHelpButton from "@/components/FloatingHelpButton";
import {useNavigate, Link } from "react-router-dom";
import api from "@/utils/api";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";

const Layout = ({ children, showSidebar = true }) => {
    const dispatch = useDispatch();
    const { logout, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const userProfile = useSelector((state) => state.user.profile);
    const loading = useSelector((state) => state.user.loading);
    const [isResending, setIsResending] = useState(false);
    const [resendSuccess, setResendSuccess] = useState(false);
    const [showVerificationDialog, setShowVerificationDialog] = useState(true);

    // Fetch user profile on mount and when auth state changes
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchUserProfile());
        }
    }, [dispatch, isAuthenticated]);

    // Show dialog when user is not verified
    useEffect(() => {
        if (userProfile?.is_email_verified === 0) {
            setShowVerificationDialog(true);
        }
    }, [userProfile?.is_email_verified]);

    const handleLogout = async () => {
        try {
            await logout();
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const handleSettingsClick = () => {
        navigate('/profile');
    };

    const handleCreditsClick = () => {
        // Add your navigation logic
        navigate('/subscription')
    };

    // Function to refetch user profile (can be called from anywhere)
    const refreshUserProfile = () => {
        if (isAuthenticated) {
            dispatch(fetchUserProfile());
        }
    };

    const handleResendVerification = async () => {
        try {
            setIsResending(true);
            await api.post('/user/verification/resend/email/');
            setResendSuccess(true);
            setTimeout(() => setResendSuccess(false), 3000);
        } catch (error) {
            console.error('Error resending verification:', error);
        } finally {
            setIsResending(false);
        }
    };

    return (
        <div className="min-h-screen bg-white">
            {/* Floating Profile Button */}
            {isAuthenticated && (
                <div className={`fixed top-4 right-4 z-30 ${showSidebar ? 'pl-60' : ''} group`}>
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={handleSettingsClick}
                        className="h-11 w-11 rounded-full bg-black/90 backdrop-blur-sm shadow-sm border border-gray-800 text-gray-300 hover:text-white hover:bg-black transition-all duration-200 transform hover:scale-110"
                    >
                        {userProfile?.email ? (
                            <div className="w-9 h-9 rounded-full flex items-center justify-center text-gray-100 text-base font-medium uppercase">
                                {userProfile.email[0]}
                            </div>
                        ) : (
                            <Settings className="h-5 w-5" />
                        )}
                    </Button>
                    <div className="absolute right-0 top-full mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                        <div className="bg-gray-900 text-white text-sm py-1 px-3 rounded-md whitespace-nowrap">
                            Settings & Profile
                        </div>
                    </div>
                </div>
            )}

            {/* Sidebar */}
            {showSidebar && (
                <div className="fixed left-0 top-0 w-60 h-full bg-black shadow-sm z-30 flex flex-col">
                    {/* Header */}
                    {/* Header */}
                    <Link
                        to="/"
                        className="flex-none h-16 flex items-center justify-center bg-black hover:bg-gray-800 transition-colors duration-200"
                    >
                        <h1 className="font-semibold text-lg text-white">Straight A</h1>
                    </Link>

                    {/* Scrollable Navigation Area */}
                    <div className="flex-1 flex flex-col min-h-0">
                        <ScrollArea className="flex-1">
                            <div className="p-3 space-y-2">
                                <Button
                                    variant="ghost"
                                    className="w-full justify-start text-base text-white hover:bg-gray-800 hover:text-white"
                                    onClick={() => navigate('/rewrite')}
                                >
                                    <FileText className="mr-2 h-4 w-4"/>
                                    AI Humanize
                                </Button>
                                <Button
                                    variant="ghost"
                                    className="w-full justify-start text-base text-white hover:bg-gray-800 hover:text-white"
                                    onClick={() => navigate('/reference/rewrite')}
                                >
                                    <BookMarked className="mr-2 h-4 w-4"/>
                                    Add Reference
                                </Button>
                                <Button
                                    variant="ghost"
                                    className="w-full justify-start text-base text-white hover:bg-gray-800 hover:text-white"
                                    onClick={() => navigate('/detection/turnitin')}
                                >
                                    <img src="/images/turnitin_icon.png" alt="Turnitin" className="mr-2 h-4 w-4"/>
                                    Turnitin Report
                                </Button>
                                <Button
                                    variant="ghost"
                                    className="w-full justify-start text-base text-white hover:bg-gray-800 hover:text-white"
                                    onClick={() => navigate('/tutor/request')}
                                >
                                    <Handshake className="mr-2 h-4 w-4"/>
                                    Tutor Help
                                </Button>
                                {isAuthenticated && (
                                    <Button
                                        variant="ghost"
                                        className="w-full justify-start text-base text-white hover:bg-gray-800 hover:text-white"
                                        onClick={() => navigate('/profile')}
                                    >
                                        <Settings className="mr-2 h-4 w-4"/>
                                        Settings & Profile
                                    </Button>
                                )}
                            </div>
                        </ScrollArea>
                    </div>

                    {/* User Section - Fixed at Bottom */}
                    {isAuthenticated && (
                        <div className="flex-none p-3 border-t border-gray-800 space-y-3">
                            {/* Email Verification Warning */}
                            {userProfile?.is_email_verified === 0 && (
                                <div className="px-2 py-1 text-xs text-pink-500 rounded-md flex items-center justify-between">
                                    <span>Please verify your email to access all features.</span>
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={handleResendVerification}
                                        disabled={isResending || resendSuccess}
                                        className="h-6 px-2 text-xs"
                                    >
                                        {resendSuccess ? (
                                            <Check className="h-3 w-3 text-green-500"/>
                                        ) : (
                                            <RefreshCw className={`h-3 w-3 ${isResending ? 'animate-spin' : ''}`}/>
                                        )}
                                    </Button>
                                </div>
                            )}

                            {/* User Email and Settings */}
                            {/* <div className="flex items-center justify-between px-2">
                                <div className="text-sm text-gray-400 truncate flex-1">
                                    {userProfile?.email || 'Email'}
                                </div>
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={handleSettingsClick}
                                    className="h-8 w-8 text-gray-400 hover:text-white hover:bg-gray-800"
                                >
                                    <Settings className="h-4 w-4" />
                                </Button>
                            </div> */}

                            {/* Credits/Subscription Display */}
                            <div className="flex flex-col space-y-1 px-2">
                                <div className="flex items-center justify-between">
                                    <div className="text-sm text-gray-400">
                                        Plan: <span className="text-white font-medium">{userProfile?.has_subscription ? userProfile.subscription_plan_name : 'Free'}</span>
                                    </div>
                                    {!(userProfile?.has_subscription && userProfile?.subscription_plan_id === 2 && userProfile?.is_active) && (
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={handleCreditsClick}
                                            className="h-8 w-8 text-gray-400 hover:text-white hover:bg-gray-800"
                                        >
                                            <CreditCard className="h-4 w-4" />
                                        </Button>
                                    )}
                                </div>
                                {userProfile?.has_subscription ? (
                                    <>
                                        {userProfile.subscription_end_time && (
                                            <div className="text-sm text-gray-400">
                                                Expires: <span className="text-white">{new Date(userProfile.subscription_end_time * 1000).toLocaleDateString('en-US', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit'
                                                })}</span>
                                            </div>
                                        )}
                                        {userProfile.show_renew_reminder && (
                                            <div
                                                className="text-sm text-pink-500 cursor-pointer hover:text-white transition-colors"
                                                onClick={handleCreditsClick}
                                            >
                                                Your subscription is expiring soon. Click to renew!
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="text-sm text-gray-400">
                                        Credits: <span className="text-white font-medium">{loading ? "Loading..." : (userProfile?.credit || 0)}</span>
                                    </div>
                                )}
                            </div>

                            {/* Referral Code Section */}
                            <div className="px-2">
                                <div className="text-sm text-gray-400">
                                    Refer a friend to get 5 credits! Code: <span className="text-white font-medium">{userProfile?.referral_code || 'Loading...'}</span>
                                </div>
                            </div>

                            {/* Logout Button */}
                            <Button
                                variant="ghost"
                                onClick={handleLogout}
                                className="w-full justify-start text-sm text-white hover:bg-gray-800 hover:text-white"
                            >
                                <LogOut className="mr-2 h-4 w-4" />
                                Logout
                            </Button>
                        </div>
                    )}
                </div>
            )}

            {/* Main content - removed extra padding since we no longer have a top nav */}
            <div className={`${showSidebar ? "pl-60" : ""}`}>
                <div className="p-8">
                    {children}
                </div>
            </div>

            {/* Floating Help Button */}
            <FloatingHelpButton />

            {/* Email Verification Dialog */}
            <AlertDialog open={showVerificationDialog && userProfile?.is_email_verified === 0}>
                <AlertDialogContent className="bg-white">
                    <AlertDialogHeader>
                        <AlertDialogTitle>Email Verification Required</AlertDialogTitle>
                        <AlertDialogDescription className="space-y-4">
                            <p>
                                We've sent a verification email to you. Please verify your email to access all features. Or you can contact customer service to bypass verification.
                            </p>
                            <div className="flex items-center gap-2 bg-gray-100 p-3 rounded-md">
                                <p className="text-sm text-gray-600">
                                    Haven't received the email?
                                </p>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={handleResendVerification}
                                    disabled={isResending || resendSuccess}
                                    className="h-8"
                                >
                                    {resendSuccess ? (
                                        <>
                                            <Check className="h-4 w-4 mr-2 text-green-500" />
                                            Sent!
                                        </>
                                    ) : (
                                        <>
                                            <RefreshCw className={`h-4 w-4 mr-2 ${isResending ? 'animate-spin' : ''}`} />
                                            Resend Email
                                        </>
                                    )}
                                </Button>
                            </div>
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogAction onClick={() => setShowVerificationDialog(false)}>
                            Got it
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
};

export default Layout;
