import React, { useState } from 'react';
import { CreditCard, ExternalLink, CheckCircle } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import Layout from "@/components/Layout";
import api from "@/utils/api";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const Subscription = () => {
    // Subscription payment states
    const [subscriptionPaymentSuccess, setSubscriptionPaymentSuccess] = useState(false);
    const [subscriptionPaymentError, setSubscriptionPaymentError] = useState(null);
    const [paymentAmount] = useState(29.99);
    const [showPayPalButton, setShowPayPalButton] = useState(false);

    // Credit payment states
    const [creditPaymentSuccess, setCreditPaymentSuccess] = useState(false);
    const [creditPaymentError, setCreditPaymentError] = useState(null);
    const [showCreditPayPalButton, setShowCreditPayPalButton] = useState(false);
    const [creditCounts, setCreditCounts] = useState({
        one: 0,  // 1 credit
        ten: 0   // 10 credits
    });
    const [totalCredits, setTotalCredits] = useState(0);
    const [totalCreditAmount, setTotalCreditAmount] = useState(0);

    // Credit prices
    const creditPrices = {
        one: 1.00,   // 1 credit
        ten: 9.00,   // 10 credits
    };

    // PayPal configuration options
    const subscriptionOptions = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || "Ab4J3PVXhANr58Ixng8Hg94ZXOkcNkAXI9SadyZWCITEJq-BWC-iTjdiKyBXz_HHIsEPdYhPAhSjmd_a",
        vault: true,
        intent: "subscription"
    };

    const captureOptions = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || "Ab4J3PVXhANr58Ixng8Hg94ZXOkcNkAXI9SadyZWCITEJq-BWC-iTjdiKyBXz_HHIsEPdYhPAhSjmd_a",
        intent: "capture"
    };

    // Toggle PayPal button visibility
    const togglePayPalButton = () => {
        setShowPayPalButton(!showPayPalButton);
        // Reset messages when toggling
        setSubscriptionPaymentSuccess(false);
        setSubscriptionPaymentError(null);
    };

    // Handler for WeChat payment
    const handleWeChatPayment = () => {
        window.open('https://afh1lmpx0eddz0cs.mikecrm.com/zsjs2X3', '_blank');
    };

    // Create PayPal order
    const createSubscription = (data, actions) => {
        return api.post('/subscription/paypal/creation/', {
            // TODO: Decide by selection
            plan_id: 2,
        })
            .then(response => {
                if (response.data.code !== 10000) {
                    throw new Error(response.data.message);
                }
                return response.data.body.subscription_id;
            });
    };

    // Handle successful payment
    const onSubscriptionApprove = async (data, actions) => {
        try {
            const subscriptionId = data.subscriptionID

            const response = await api.post('/subscription/paypal/activation/', {
                subscription_id: subscriptionId,
            });

            if (response.data.code !== 10000) {
                throw new Error(response.data.message);
            }

            setSubscriptionPaymentSuccess(true);
            setSubscriptionPaymentError(null);

            // Reset after success
            setTimeout(() => {
                setSubscriptionPaymentSuccess(false);
                setShowPayPalButton(false);
            }, 3000);
        } catch (error) {
            console.error('Credit payment processing error:', error);
            setCreditPaymentError('Failed to process credit payment. Please try again.');
        }
    };

    // PayPal error handler
    const onError = (err) => {
        console.error('PayPal error:', err);
        setSubscriptionPaymentError('There was an error with the payment system. Please try again.');
    };

    // Credit quantity adjustment functions
    const increaseCreditCount = (type) => {
        setCreditCounts(prev => {
            const newCounts = { ...prev, [type]: prev[type] + 1 };
            updateTotalCredits(newCounts);
            return newCounts;
        });
    };

    const decreaseCreditCount = (type) => {
        if (creditCounts[type] > 0) {
            setCreditCounts(prev => {
                const newCounts = { ...prev, [type]: prev[type] - 1 };
                updateTotalCredits(newCounts);
                return newCounts;
            });
        }
    };

    const updateTotalCredits = (counts) => {
        const totalCredits = counts.one * 1 + counts.ten * 10;
        const totalAmount = counts.one * creditPrices.one + counts.ten * creditPrices.ten;

        setTotalCredits(totalCredits);
        setTotalCreditAmount(totalAmount);
    };

    // Toggle Credit PayPal button visibility
    const toggleCreditPayPalButton = () => {
        setShowCreditPayPalButton(!showCreditPayPalButton);
        // Reset messages when toggling
        setCreditPaymentSuccess(false);
        setCreditPaymentError(null);
    };

    // Handler for WeChat payment for credits
    const handleCreditWeChatPayment = () => {
        window.open('https://afh1lmpx0eddz0cs.mikecrm.com/creditPurchase', '_blank');
    };

    // Create PayPal order for credits
    const createCreditOrder = (data, actions) => {
        return api.post('/subscription/paypal/credits/order/', {
            order_credits: {
                one: creditCounts.one,
                ten: creditCounts.ten
            },
            total_credits: totalCredits,
            amount: totalCreditAmount,
        })
        .then(response => {
            if (response.data.code !== 10000) {
                throw new Error(response.data.message);
            }
            return response.data.body.order_id;
        });
    };

    // Handle successful credit payment
    const onCreditApprove = async (data, actions) => {
        try {
            const orderId = data.orderID

            const response = await api.post('/subscription/papal/credits/approval/', {
                order_id: orderId,
            });

            if (response.data.code !== 10000) {
                throw new Error(response.data.message);
            }

            setCreditPaymentSuccess(true);
            setCreditPaymentError(null);

            // Reset after success
            setTimeout(() => {
                setCreditPaymentSuccess(false);
                setShowCreditPayPalButton(false);
                setCreditCounts({ one: 0, five: 0, ten: 0 });
                setTotalCredits(0);
                setTotalCreditAmount(0);
            }, 3000);
        } catch (error) {
            console.error('Credit payment processing error:', error);
            setCreditPaymentError('Failed to process credit payment. Please try again.');
        }
    };

    // Credit PayPal error handler
    const onCreditError = (err) => {
        console.error('PayPal error:', err);
        setCreditPaymentError('There was an error with the payment system. Please try again.');
    };

    return (
        <Layout>
            <div className="min-h-screen">
                <div className="max-w-4xl mx-auto space-y-8 py-12 px-4 sm:px-6">
                    <div className="text-center mb-8">
                        <h1 className="text-3xl font-bold mb-2">Subscription Plans</h1>
                        <p className="text-gray-500">Choose the plan that works best for you</p>
                    </div>

                    <Card className="bg-gray-800 border border-gray-700 shadow-lg hover:shadow-pink-500/10 transition-shadow duration-300">
                        <CardHeader className="border-b border-gray-700 pb-4 bg-gradient-to-r from-gray-800 to-gray-900">
                            <CardTitle className="flex items-center space-x-3 text-white">
                                <CreditCard className="h-6 w-6 text-pink-400" />
                                <span className="text-xl">Subscription Plan</span>
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="pt-6">
                            <div className="space-y-6">
                                <div className="p-6 border border-gray-700 rounded-lg bg-gray-900">
                                    <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
                                        <div>
                                            <h3 className="text-xl font-medium mb-2 text-white">Monthly & Weekly Package</h3>
                                            <p className="text-gray-300">
                                                Unlimited Access - Cancel anytime
                                            </p>
                                        </div>
                                    </div>

                                    <div className="mb-6 p-4 bg-gray-850 rounded-lg border border-gray-700">
                                        <ul className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-gray-300">
                                            <li className="flex items-center">
                                                <CheckCircle className="h-5 w-5 text-pink-400 mr-2 flex-shrink-0" />
                                                <span>Unlimited humanization & rewrites</span>
                                            </li>
                                            <li className="flex items-center">
                                                <CheckCircle className="h-5 w-5 text-pink-400 mr-2 flex-shrink-0" />
                                                <span>Access to all premium features</span>
                                            </li>
                                            <li className="flex items-center">
                                                <CheckCircle className="h-5 w-5 text-pink-400 mr-2 flex-shrink-0" />
                                                <span>No credit limits</span>
                                            </li>
                                            <li className="flex items-center">
                                                <CheckCircle className="h-5 w-5 text-pink-400 mr-2 flex-shrink-0" />
                                                <span>Priority support</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="flex flex-col sm:flex-row gap-3 mt-6">
                                        <Button
                                            onClick={togglePayPalButton}
                                            className={showPayPalButton
                                                ? "bg-gray-700 hover:bg-gray-600 border border-gray-600"
                                                : "bg-[#ffc439] hover:bg-[#f3ba32] text-[#003087] font-medium flex-1"
                                            }
                                        >
                                            {showPayPalButton ? "Hide Payment Options" : (
                                                <span className="flex items-center justify-center">
                                                    <img
                                                        src="/images/paypal_logo.svg"
                                                        alt="PayPal"
                                                        width="20"
                                                        height="20"
                                                        className="mr-2"
                                                    />
                                                    Pay with PayPal
                                                </span>
                                            )}
                                        </Button>
                                        <Button
                                            onClick={handleWeChatPayment}
                                            className="bg-pink-500 hover:bg-pink-600 flex-1 flex items-center justify-center"
                                        >
                                            Pay with WeChat/AliPay <ExternalLink className="ml-2 h-4 w-4" />
                                        </Button>
                                    </div>

                                    {subscriptionPaymentSuccess && (
                                        <Alert className="mb-4 bg-green-900 border-green-700 text-green-100">
                                            <AlertTitle>Payment Successful!</AlertTitle>
                                            <AlertDescription>
                                                Your subscription has been activated successfully.
                                            </AlertDescription>
                                        </Alert>
                                    )}

                                    {subscriptionPaymentError && (
                                        <Alert variant="destructive" className="mb-4 bg-red-900 border-red-800 text-red-100">
                                            <AlertTitle>Payment Error</AlertTitle>
                                            <AlertDescription>{subscriptionPaymentError}</AlertDescription>
                                        </Alert>
                                    )}

                                    {showPayPalButton && (
                                        <div className="mt-6 border-t border-gray-700 pt-6">
                                            <div className="bg-gray-800 border border-gray-700 p-6 rounded-lg shadow-md mb-6">
                                                <h4 className="font-medium mb-4 text-white text-lg">Subscription Details:</h4>

                                                <div className="mt-6 p-4 bg-gray-900 border border-gray-700 rounded-md">
                                                    <div className="flex justify-between font-medium text-white">
                                                        <span>Monthly Premium Plan:</span>
                                                        <span>Unlimited Access</span>
                                                    </div>
                                                    <div className="flex justify-between font-bold text-pink-400 text-xl mt-2">
                                                        <span>Monthly Price:</span>
                                                        <span>${paymentAmount.toFixed(2)}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <PayPalScriptProvider options={subscriptionOptions}>
                                                <PayPalButtons
                                                    createSubscription={createSubscription}
                                                    onApprove={onSubscriptionApprove}
                                                    onError={onError}
                                                    style={{ layout: "vertical", color: "gold", shape: "pill" }}
                                                />
                                            </PayPalScriptProvider>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* New Credit Purchase Card */}
                    <Card className="bg-gray-800 border border-gray-700 shadow-lg hover:shadow-pink-500/10 transition-shadow duration-300">
                        <CardHeader className="border-b border-gray-700 pb-4 bg-gradient-to-r from-gray-800 to-gray-900">
                            <CardTitle className="flex items-center space-x-3 text-white">
                                <CreditCard className="h-6 w-6 text-pink-400" />
                                <span className="text-xl">One-Time Credit Purchase</span>
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="pt-6">
                            <div className="space-y-6">
                                <div className="p-6 border border-gray-700 rounded-lg bg-gray-900">
                                    <div className="mb-6">
                                        <h3 className="text-xl font-medium mb-2 text-white">Purchase Credits</h3>
                                        <p className="text-gray-300">
                                            Buy credits as needed - No recurring charges
                                        </p>
                                    </div>

                                    <div className="mb-6 p-4 bg-gray-850 rounded-lg border border-gray-700">
                                        <ul className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-gray-300">
                                            <li className="flex items-center">
                                                <CheckCircle className="h-5 w-5 text-pink-400 mr-2 flex-shrink-0" />
                                                <span>Use credits for premium features</span>
                                            </li>
                                            <li className="flex items-center">
                                                <CheckCircle className="h-5 w-5 text-pink-400 mr-2 flex-shrink-0" />
                                                <span>Credits never expire</span>
                                            </li>
                                            <li className="flex items-center">
                                                <CheckCircle className="h-5 w-5 text-pink-400 mr-2 flex-shrink-0" />
                                                <span>No subscription required</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="flex flex-col sm:flex-row gap-3 mt-6">
                                        <Button
                                            onClick={toggleCreditPayPalButton}
                                            className={showCreditPayPalButton
                                                ? "bg-gray-700 hover:bg-gray-600 border border-gray-600"
                                                : "bg-[#ffc439] hover:bg-[#f3ba32] text-[#003087] font-medium flex-1"
                                            }
                                        >
                                            {showCreditPayPalButton ? "Hide Payment Options" : (
                                                <span className="flex items-center justify-center">
                                                    <img
                                                        src="/images/paypal_logo.svg"
                                                        alt="PayPal"
                                                        width="20"
                                                        height="20"
                                                        className="mr-2"
                                                    />
                                                    Pay with PayPal
                                                </span>
                                            )}
                                        </Button>
                                        <Button
                                            onClick={handleCreditWeChatPayment}
                                            className="bg-pink-500 hover:bg-pink-600 flex-1 flex items-center justify-center"
                                        >
                                            Pay with WeChat/AliPay <ExternalLink className="ml-2 h-4 w-4" />
                                        </Button>
                                    </div>

                                    {creditPaymentSuccess && (
                                        <Alert className="mb-4 bg-green-900 border-green-700 text-green-100">
                                            <AlertTitle>Payment Successful!</AlertTitle>
                                            <AlertDescription>
                                                Your credits have been added to your account successfully.
                                            </AlertDescription>
                                        </Alert>
                                    )}

                                    {creditPaymentError && (
                                        <Alert variant="destructive" className="mb-4 bg-red-900 border-red-800 text-red-100">
                                            <AlertTitle>Payment Error</AlertTitle>
                                            <AlertDescription>{creditPaymentError}</AlertDescription>
                                        </Alert>
                                    )}

                                    {showCreditPayPalButton && (
                                        <div className="mt-6 border-t border-gray-700 pt-6">
                                            <div className="bg-gray-800 border border-gray-700 p-6 rounded-lg shadow-md mb-6">
                                                <h4 className="font-medium mb-4 text-white text-lg">Select Credit Package:</h4>

                                                <div className="space-y-6">
                                                    {/* 1 Credit option */}
                                                    <div className="flex items-center justify-between border-b border-gray-700 pb-4 px-4 py-3 bg-gray-850 rounded-lg">
                                                        <div>
                                                            <span className="font-medium text-white text-lg">1 Credit</span>
                                                            <p className="text-sm text-gray-300 mt-1">Perfect for small tasks</p>
                                                        </div>
                                                        <div className="flex flex-col items-end">
                                                            <span className="font-bold text-pink-400 text-xl">${creditPrices.one}</span>
                                                            <div className="flex items-center space-x-3 mt-2">
                                                                <Button
                                                                    onClick={() => decreaseCreditCount('one')}
                                                                    variant="outline"
                                                                    size="sm"
                                                                    className="h-8 w-8 rounded-full p-0 border-gray-600 text-gray-200 bg-gray-700 hover:bg-pink-500 hover:text-white"
                                                                    disabled={creditCounts.one === 0}
                                                                >
                                                                    -
                                                                </Button>
                                                                <span className="w-8 text-center text-white">{creditCounts.one}</span>
                                                                <Button
                                                                    onClick={() => increaseCreditCount('one')}
                                                                    variant="outline"
                                                                    size="sm"
                                                                    className="h-8 w-8 rounded-full p-0 border-gray-600 text-gray-200 bg-gray-700 hover:bg-pink-500 hover:text-white"
                                                                >
                                                                    +
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* 10 Credits option */}
                                                    <div className="flex items-center justify-between pb-2 px-4 py-3 bg-gray-850 rounded-lg">
                                                        <div>
                                                            <span className="font-medium text-white text-lg">10 Credits</span>
                                                            <p className="text-sm text-gray-300 mt-1">Best value package</p>
                                                        </div>
                                                        <div className="flex flex-col items-end">
                                                            <span className="font-bold text-pink-400 text-xl">${creditPrices.ten}</span>
                                                            <div className="flex items-center space-x-3 mt-2">
                                                                <Button
                                                                    onClick={() => decreaseCreditCount('ten')}
                                                                    variant="outline"
                                                                    size="sm"
                                                                    className="h-8 w-8 rounded-full p-0 border-gray-600 text-gray-200 bg-gray-700 hover:bg-pink-500 hover:text-white"
                                                                    disabled={creditCounts.ten === 0}
                                                                >
                                                                    -
                                                                </Button>
                                                                <span className="w-8 text-center text-white">{creditCounts.ten}</span>
                                                                <Button
                                                                    onClick={() => increaseCreditCount('ten')}
                                                                    variant="outline"
                                                                    size="sm"
                                                                    className="h-8 w-8 rounded-full p-0 border-gray-600 text-gray-200 bg-gray-700 hover:bg-pink-500 hover:text-white"
                                                                >
                                                                    +
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-6 p-4 bg-gray-900 border border-gray-700 rounded-md">
                                                    <div className="flex justify-between font-medium text-white">
                                                        <span>Total Credits:</span>
                                                        <span>{totalCredits}</span>
                                                    </div>
                                                    <div className="flex justify-between font-bold text-pink-400 text-xl mt-2">
                                                        <span>Total Price:</span>
                                                        <span>${totalCreditAmount.toFixed(2)}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            {totalCredits > 0 && (
                                                <PayPalScriptProvider options={captureOptions}>
                                                    <PayPalButtons
                                                        createOrder={createCreditOrder}
                                                        onApprove={onCreditApprove}
                                                        onError={onCreditError}
                                                        style={{ layout: "vertical", color: "gold", shape: "pill" }}
                                                    />
                                                </PayPalScriptProvider>
                                            )}

                                            {totalCredits === 0 && (
                                                <p className="text-center text-gray-300 my-4">
                                                    Please select at least one credit package to proceed with payment
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </Layout>
    );
};

export default Subscription;
