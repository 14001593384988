import React, { useState } from 'react';
import { FileText, Send, Loader2, Upload } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import Layout from "@/components/Layout";
import api from "@/utils/api";

const MAX_CHARS_PER_WORD = 30;
const MAX_WORD_COUNT = 1000;

const TutorRequest = () => {
    const [description, setDescription] = useState('');
    const [wechatNumber, setWechatNumber] = useState('');
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [wordCount, setWordCount] = useState(0);

    const countWords = (text) => {
        if (!text || !text.trim()) return 0;
        // First, normalize whitespace and trim the text
        const normalizedText = text.trim().replace(/\s+/g, ' ');
        // Split the text into potential words
        const words = normalizedText.split(' ');
        // Process each word to handle edge cases
        return words.reduce((acc, word) => {
            if (!word) return acc;

            // If word is longer than MAX_CHARS_PER_WORD (30 chars),
            // it might be multiple words stuck together
            if (word.length > MAX_CHARS_PER_WORD) {
                // 1. Split by capital letters (CamelCase)
                // "HelloWorld" -> ["Hello", "World"]
                const camelCaseSplit = word.split(/(?=[A-Z])/g);

                // 2. Split by numbers and punctuations
                // "thisIs1Word" -> ["thisIs", "1", "Word"]
                const withNumbersSplit = camelCaseSplit
                    .flatMap(part => part.split(/(?<=\d)(?=[a-zA-Z])|(?<=[a-zA-Z])(?=\d)/g));

                // 3. Handle any remaining long parts by breaking them into chunks
                const finalParts = withNumbersSplit.flatMap(part => {
                    if (part.length > MAX_CHARS_PER_WORD) {
                        // Break very long strings into chunks of MAX_CHARS_PER_WORD
                        const chunks = [];
                        for (let i = 0; i < part.length; i += MAX_CHARS_PER_WORD) {
                            chunks.push(part.slice(i, i + MAX_CHARS_PER_WORD));
                        }
                        return chunks;
                    }
                    return [part];
                });

                // Add the number of parts to our word count
                return acc + finalParts.length;
            }

            // If it's a normal word, just add 1 to our count
            return acc + 1;

        }, 0);
    };

    const handleDescriptionChange = (e) => {
        const text = e.target.value;
        const newWordCount = countWords(text);

        if (newWordCount > MAX_WORD_COUNT) {
            setError(`Text exceeds maximum word limit of ${MAX_WORD_COUNT} words`);
        } else {
            setError(null);
        }

        setDescription(text);
        setWordCount(newWordCount);
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length + files.length > 3) {
            setError('Maximum 3 files allowed');
            return;
        }
        setFiles([...files, ...selectedFiles]);
        setError(null);
    };

    const removeFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    const handleSubmit = async () => {
        if (!description.trim()) {
            setError('Please enter a description.');
            return;
        }

        if (wordCount > MAX_WORD_COUNT) {
            setError(`Text exceeds maximum word limit of ${MAX_WORD_COUNT} words`);
            return;
        }

        if (!wechatNumber.trim()) {
            setError('Please enter your WeChat number.');
            return;
        }

        setIsLoading(true);
        setError(null);
        setSuccess(false);

        const formData = new FormData();
        formData.append('user_description', description);
        formData.append('wechat_number', wechatNumber);
        
        files.forEach((file) => {
            formData.append('files', file);
        });

        try {
            // Override the Content-Type header just for this request
            const response = await api.post('/tutor/request/', formData, {
                headers: {
                    ...api.defaults.headers,
                    'Content-Type': undefined  // This will let the browser set the correct multipart/form-data header
                }
            });

            setSuccess(true);
            setDescription('');
            setWechatNumber('');
            setFiles([]);
        } catch (error) {
            console.error('Error submitting tutor request:', error);
            setError(error.response?.data?.message || 'Failed to submit request. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Layout>
            <div className="max-w-3xl mx-auto space-y-6">
                <Card>
                    <CardHeader>
                        <CardTitle className="text-center text-2xl">
                            Upload & Get Help
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-6">
                        <Alert variant="info" className="bg-pink-50">
                            <div className="px-4">
                                <AlertTitle className="text-base font-medium mb-2">How to Use</AlertTitle>
                                <AlertDescription>
                                    <ol className="list-decimal ml-4 space-y-2">
                                        <li className="pl-2">
                                            <span className="font-medium">Provide Assignment Details</span>
                                            <br />
                                            Enter your assignment instructions and attach any relevant files. Make sure to be as detailed as possible to help our tutors fully understand the requirements.
                                        </li>
                                        <li className="pl-2">
                                            <span className="font-medium">Add Your WeChat</span>
                                            <br />
                                            Type in your WeChat ID so our representative can reach out to clarify any details about your assignment.
                                        </li>
                                        <li className="pl-2">
                                            <span className="font-medium">Receive a Quote</span>
                                            <br />
                                            Our team will review your assignment details to determine the price, which varies based on complexity and word count.
                                        </li>
                                    </ol>
                                </AlertDescription>
                            </div>
                        </Alert>

                        {error && (
                            <Alert variant="destructive">
                                <AlertTitle>Error</AlertTitle>
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        )}

                        {success && (
                            <Alert className="bg-green-50 border-green-200">
                                <AlertTitle>Request Submitted Successfully</AlertTitle>
                                <AlertDescription>
                                    Our team will review your request and contact you through WeChat with a quote soon.
                                </AlertDescription>
                            </Alert>
                        )}

                        <div className="space-y-4">
                            <div>
                                <span className="font-semibold">Assignment Details</span>
                                <Textarea
                                    placeholder="Please describe your assignment requirements in detail..."
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    className="h-48 mt-2"
                                />
                                <div className="flex items-center justify-between mt-2">
                                    <div className="flex items-center space-x-2">
                                        <FileText className="h-4 w-4"/>
                                        <span className={`text-sm ${wordCount > MAX_WORD_COUNT ? 'text-red-500' : ''}`}>
                                            Word count: {wordCount} / {MAX_WORD_COUNT}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <span className="font-semibold">WeChat ID</span>
                                <input
                                    type="text"
                                    placeholder="Enter your WeChat ID for contact..."
                                    value={wechatNumber}
                                    onChange={(e) => setWechatNumber(e.target.value)}
                                    className="w-full mt-2 p-2 border rounded-md"
                                />
                            </div>

                            <div>
                                <span className="font-semibold">Assignment Files</span>
                                <div className="mt-2 space-y-2">
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        className="hidden"
                                        id="file-upload"
                                        multiple
                                    />
                                    <label
                                        htmlFor="file-upload"
                                        className="cursor-pointer inline-flex items-center px-4 py-2 border rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                                    >
                                        <Upload className="h-4 w-4 mr-2" />
                                        Upload Files
                                    </label>
                                    <div className="text-sm text-gray-500">
                                        Maximum 3 files allowed
                                    </div>
                                    {files.length > 0 && (
                                        <div className="space-y-2">
                                            {files.map((file, index) => (
                                                <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                                                    <span className="text-sm">{file.name}</span>
                                                    <button
                                                        onClick={() => removeFile(index)}
                                                        className="text-red-500 hover:text-red-700"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="flex justify-center">
                                <Button
                                    onClick={handleSubmit}
                                    disabled={isLoading || !description.trim() || !wechatNumber.trim() || wordCount > MAX_WORD_COUNT}
                                    className="px-8"
                                >
                                    {isLoading ? (
                                        <>
                                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                            Submitting...
                                        </>
                                    ) : (
                                        <>
                                            <Send className="mr-2 h-4 w-4" />
                                            Submit Request
                                        </>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </Layout>
    );
};

export default TutorRequest;
