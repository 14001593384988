import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CreditCard, AlertTriangle, Check } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import Layout from "@/components/Layout";
import api from "@/utils/api";
import { useNavigate } from "react-router-dom";

const ManageSubscription = () => {
    const userProfile = useSelector((state) => state.user.profile);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [cancelError, setCancelError] = useState(null);
    const navigate = useNavigate();

    const handleCancelSubscription = async () => {
        try {
            setIsLoading(true);
            setCancelError(null);

            // API call to cancel subscription
            const response = await api.post('/subscription/paypal/cancel/', {
                plan_id: userProfile.subscription_plan_id,
            });

            if (response.data.code !== 10000) {
                throw new Error(response.data.message || 'Failed to cancel subscription');
            }

            setCancelSuccess(true);
            setConfirmOpen(false);

            // Redirect to profile page after a delay
            setTimeout(() => {
                navigate('/profile');
            }, 3000);

        } catch (error) {
            console.error('Error cancelling subscription:', error);
            setCancelError(error.message || 'Failed to cancel subscription. Please try again later.');
            setConfirmOpen(false);
        } finally {
            setIsLoading(false);
        }
    };

    const formatSubscriptionDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        return new Date(timestamp * 1000).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    return (
        <Layout>
            <div className="max-w-3xl mx-auto py-12 px-4 sm:px-6">
                <h1 className="text-3xl font-bold mb-8 text-center">Manage Your Subscription</h1>

                <Card className="shadow-lg border-gray-200 mb-6">
                    <CardHeader className="border-b pb-4">
                        <CardTitle className="flex items-center space-x-3">
                            <CreditCard className="h-6 w-6 text-pink-500" />
                            <span>Current Subscription</span>
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="pt-6">
                        <div className="space-y-4">
                            <div className="flex flex-col space-y-1">
                                <span className="font-medium text-gray-500">Plan:</span>
                                <span className="text-xl font-semibold text-gray-900">
                                    {userProfile?.subscription_plan_name || 'Loading...'}
                                </span>
                            </div>

                            {userProfile?.next_billing_date && (
                                <div className="flex flex-col space-y-1">
                                    <span className="font-medium text-gray-500">Next Billing Date:</span>
                                    <span className="text-gray-900">
                                        {formatSubscriptionDate(userProfile.next_billing_date)}
                                    </span>
                                </div>
                            )}

                            <div className="mt-6 pt-6 border-t border-gray-200">
                                <Button
                                    variant="destructive"
                                    className="w-full sm:w-auto"
                                    onClick={() => setConfirmOpen(true)}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Processing...' : 'Cancel Subscription'}
                                </Button>
                                <p className="mt-2 text-sm text-gray-500">
                                    Your subscription will remain active until the end of your current billing period.
                                </p>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {cancelSuccess && (
                    <Alert className="bg-green-50 border-green-200 text-green-800">
                        <AlertTitle className="flex items-center">
                            <Check className="h-4 w-4 mr-2" />
                            Subscription Cancelled
                        </AlertTitle>
                        <AlertDescription>
                            Your subscription has been successfully cancelled. You will continue to have access until the end of your current billing period. Redirecting to your profile...
                        </AlertDescription>
                    </Alert>
                )}

                {cancelError && (
                    <Alert variant="destructive">
                        <AlertTitle className="flex items-center">
                            <AlertTriangle className="h-4 w-4 mr-2" />
                            Error
                        </AlertTitle>
                        <AlertDescription>{cancelError}</AlertDescription>
                    </Alert>
                )}

                <AlertDialog open={confirmOpen} onOpenChange={setConfirmOpen}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Cancel Subscription?</AlertDialogTitle>
                            <AlertDialogDescription>
                                Are you sure you want to cancel your subscription? You will continue to have access to premium features until the end of your current billing period.
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Keep Subscription</AlertDialogCancel>
                            <AlertDialogAction
                                onClick={handleCancelSubscription}
                                className="bg-red-500 hover:bg-red-600"
                            >
                                Yes, Cancel Subscription
                            </AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </div>
        </Layout>
    );
};

export default ManageSubscription;
